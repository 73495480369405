@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-bg-color {
  background-color: #fe0002;
}

.custom-text-color {
  color: #fe0002;
}

/* ActionButtonNotClicked */
.ActionButtonNotClicked {
  text-transform: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 10px;
  z-index: 5;
}

/* Animation keyframes */
@keyframes glowing {
  0% {
    box-shadow: 0 0 0 0 #191c21;
  }
  15% {
    box-shadow: 0 0 0 0px #fff;
  }
  50% {
    box-shadow: 0 0 0 0px #191c21;
  }
  100% {
    box-shadow: 0 0 0 0 #ffffff;
  }
}

@keyframes border {
  0%,
  100% {
    background-position: 0 0;
  }
  50% {
    background-position: 300%;
  }
}

/* ActionButton */
.ActionButton {
  text-transform: none;
  border-radius: 5px;
  animation: glowing 6s infinite, border 12s linear infinite;
  background: linear-gradient(
    339.6deg,
    #fe0002 22.61%,
    rgb(10, 0, 2) 49.45%,
    #fe0002 76.68%
  );
  background-size: 300%;
}
