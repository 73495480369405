@media (max-width: 768px) {
  .recaptcha-container {
    transform: scale(0.8) !important;
  }
}

@media (max-width: 480px) {
  .recaptcha-container {
    transform: scale(0.8) !important;
  }
}
